import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { CheckCircle, XCircle, Loader, Filter, Search, Plus, X } from 'lucide-react';
import api, { getAreaManagerSalaryUpgrades, updateAreaManagerSalaryUpgradeStatus, createAreaManagerSalaryUpgrade } from '../../utils/api';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';

const AreaManagerSalaryUpgrades = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('all');
  const [selectedMonth, setSelectedMonth] = useState('all');
  const [formData, setFormData] = useState({
    branchId: '',
    employeeName: '',
    employeeNumber: '',
    idNumber: '',
    role: '',
    salaryType: 'hourly',
    currentSalary: '',
    requestedSalary: '',
    yearsInCompany: '',
    justification: ''
  });

  const hebrewMonths = [
    { value: '01', label: 'ינואר' },
    { value: '02', label: 'פברואר' },
    { value: '03', label: 'מרץ' },
    { value: '04', label: 'אפריל' },
    { value: '05', label: 'מאי' },
    { value: '06', label: 'יוני' },
    { value: '07', label: 'יולי' },
    { value: '08', label: 'אוגוסט' },
    { value: '09', label: 'ספטמבר' },
    { value: '10', label: 'אוקטובר' },
    { value: '11', label: 'נובמבר' },
    { value: '12', label: 'דצמבר' }
  ];

  useEffect(() => {
    fetchSubmissions();
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await api.get('/api/area-manager/branches');
      setBranches(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching branches:', error);
      toast.error('שגיאה בטעינת סניפים');
      setBranches([]);
    }
  };

  const fetchSubmissions = async () => {
    try {
      const data = await getAreaManagerSalaryUpgrades();
      setSubmissions(data);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      toast.error('שגיאה בטעינת הבקשות');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (submissionId, newStatus) => {
    try {
      await updateAreaManagerSalaryUpgradeStatus(submissionId, newStatus);
      toast.success('הסטטוס עודכן בהצלחה');
      fetchSubmissions();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('שגיאה בעדכון הסטטוס');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createAreaManagerSalaryUpgrade(formData);
      toast.success('הבקשה נשלחה בהצלחה');
      setIsModalOpen(false);
      setFormData({
        branchId: '',
        employeeName: '',
        employeeNumber: '',
        idNumber: '',
        role: '',
        salaryType: 'hourly',
        currentSalary: '',
        requestedSalary: '',
        yearsInCompany: '',
        justification: ''
      });
      fetchSubmissions();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || 'שגיאה בשליחת הטופס');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const filteredSubmissions = submissions
    .filter(sub => filter === 'all' || sub.status === filter)
    .filter(sub => 
      searchTerm === '' || 
      sub.employeeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sub.employeeNumber.includes(searchTerm)
    )
    .filter(sub => selectedBranch === 'all' || sub.branchName === selectedBranch)
    .filter(sub => {
      if (selectedMonth === 'all') return true;
      const submissionMonth = new Date(sub.createdAt).toISOString().slice(0, 7); // YYYY-MM format
      return submissionMonth === selectedMonth;
    });

  const getStatusText = (status) => {
    switch (status) {
      case 'approved': return 'אושר';
      case 'rejected': return 'נדחה';
      default: return 'ממתין לאישור';
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'approved': return 'bg-green-100 text-green-800';
      case 'rejected': return 'bg-red-100 text-red-800';
      default: return 'bg-yellow-100 text-yellow-800';
    }
  };

  if (loading) {
    return (
      <div className="sales-card p-6 rounded-xl shadow-lg animate-fade-in flex justify-center items-center min-h-[200px]">
        <div className="flex items-center gap-3">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
          <span className="text-gray-600 font-medium">טוען...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
      <div className="sales-card p-3 sm:p-6 rounded-xl shadow-lg animate-fade-in">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800">בקשות העלאת שכר</h2>
          <button
            onClick={() => setIsModalOpen(true)}
            className="w-full sm:w-auto btn btn-primary shadow-lg transform hover:scale-105 transition-all duration-300 flex items-center justify-center gap-2"
          >
            <Plus className="w-5 h-5 ml-2" />
            בקשה חדשה
          </button>
        </div>

        {/* Filters Section */}
        <div className="flex flex-col sm:flex-row flex-wrap gap-4 mb-6 bg-white/50 p-4 rounded-lg shadow-sm">
          {/* Status Filter */}
          <div className="relative w-full sm:w-40">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="block w-full rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right transition-all duration-300 appearance-none"
              style={{ backgroundPosition: "left 0.5rem center" }}
            >
              <option value="all">כל הסטטוסים</option>
              <option value="pending">ממתין</option>
              <option value="approved">אושר</option>
              <option value="rejected">נדחה</option>
            </select>
          </div>

          {/* Branch Filter */}
          <div className="relative w-full sm:w-40">
            <select
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              className="block w-full rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right transition-all duration-300 appearance-none"
              style={{ backgroundPosition: "left 0.5rem center" }}
            >
              <option value="all">כל הסניפים</option>
              {branches.map(branch => (
                <option key={branch._id} value={branch.name}>
                  {branch.name}
                </option>
              ))}
            </select>
          </div>

          {/* Month Filter */}
          <div className="relative w-full sm:w-40">
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="block w-full rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right transition-all duration-300 appearance-none"
              style={{ backgroundPosition: "left 0.5rem center" }}
            >
              <option value="all">כל החודשים</option>
              {hebrewMonths.map(month => (
                <option key={month.value} value={`2024-${month.value}`}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>

          {/* Search Input */}
          <div className="relative flex-grow w-full sm:w-40">
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="חיפוש לפי שם או מספר עובד..."
              className="block w-full rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right pr-10 transition-all duration-300"
            />
          </div>
        </div>

        {/* Mobile-optimized table view */}
        <div className="block sm:hidden">
          {filteredSubmissions.map((submission) => (
            <div key={submission._id} className="bg-white/30 p-4 rounded-lg mb-4 shadow-sm">
              <div className="flex justify-between items-start mb-3">
                <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeColor(submission.status)}`}>
                  {getStatusText(submission.status)}
                </span>
                <span className="text-sm text-gray-600">{format(new Date(submission.createdAt), 'dd/MM/yyyy')}</span>
              </div>
              <div className="space-y-2">
                <div>
                  <span className="text-sm font-semibold text-gray-600">שם העובד:</span>
                  <span className="text-sm text-gray-800 mr-2">{submission.employeeName}</span>
                </div>
                <div>
                  <span className="text-sm font-semibold text-gray-600">מספר עובד:</span>
                  <span className="text-sm text-gray-800 mr-2">{submission.employeeNumber}</span>
                </div>
                <div>
                  <span className="text-sm font-semibold text-gray-600">תעודת זהות:</span>
                  <span className="text-sm text-gray-800 mr-2">{submission.idNumber}</span>
                </div>
                <div>
                  <span className="text-sm font-semibold text-gray-600">תפקיד:</span>
                  <span className="text-sm text-gray-800 mr-2">{submission.role}</span>
                </div>
                <div>
                  <span className="text-sm font-semibold text-gray-600">סניף:</span>
                  <span className="text-sm text-gray-800 mr-2">{submission.branchName}</span>
                </div>
              </div>
              {submission.status === 'pending' && (
                <div className="flex justify-end gap-3 mt-4">
                  <button
                    onClick={() => handleStatusUpdate(submission._id, 'approved')}
                    className="flex items-center gap-1 px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm"
                  >
                    <CheckCircle className="h-4 w-4" />
                    אשר
                  </button>
                  <button
                    onClick={() => handleStatusUpdate(submission._id, 'rejected')}
                    className="flex items-center gap-1 px-3 py-1 bg-red-100 text-red-800 rounded-full text-sm"
                  >
                    <XCircle className="h-4 w-4" />
                    דחה
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Desktop table view */}
        <div className="hidden sm:block overflow-x-auto rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 bg-white/50">
            <thead>
              <tr className="bg-gray-50/80">
                <th className="px-6 py-4 text-right text-sm font-bold text-gray-700">תאריך הגשה</th>
                <th className="px-6 py-4 text-right text-sm font-bold text-gray-700">שם העובד</th>
                <th className="px-6 py-4 text-right text-sm font-bold text-gray-700">מספר עובד</th>
                <th className="px-6 py-4 text-right text-sm font-bold text-gray-700">תעודת זהות</th>
                <th className="px-6 py-4 text-right text-sm font-bold text-gray-700">תפקיד</th>
                <th className="px-6 py-4 text-right text-sm font-bold text-gray-700">סניף</th>
                <th className="px-6 py-4 text-right text-sm font-bold text-gray-700">סטטוס</th>
                <th className="px-6 py-4 text-right text-sm font-bold text-gray-700">פעולות</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white/30">
              {filteredSubmissions.map((submission) => (
                <tr key={submission._id} className="hover:bg-blue-50/50 transition-colors duration-200">
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                    {format(new Date(submission.createdAt), 'dd/MM/yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800 font-medium">
                    {submission.employeeName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                    {submission.employeeNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                    {submission.idNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                    {submission.role}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                    {submission.branchName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeColor(submission.status)}`}>
                      {getStatusText(submission.status)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                    <div className="flex items-center gap-2 justify-end">
                      {submission.status === 'pending' && (
                        <>
                          <button
                            onClick={() => handleStatusUpdate(submission._id, 'approved')}
                            className="text-green-600 hover:text-green-800 transition-colors"
                            title="אשר"
                          >
                            <CheckCircle className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleStatusUpdate(submission._id, 'rejected')}
                            className="text-red-600 hover:text-red-800 transition-colors"
                            title="דחה"
                          >
                            <XCircle className="h-5 w-5" />
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-2 sm:p-4 z-50 overflow-y-auto">
          <div className="relative bg-white rounded-xl shadow-xl p-4 sm:p-6 w-full max-w-2xl animate-fade-in my-8 sm:my-12 mx-auto">
            <div className="sticky top-0 bg-white pb-4 border-b border-gray-200 mb-6">
              <div className="flex justify-between items-center">
                <h3 className="text-lg sm:text-xl font-bold text-gray-800">הגשת בקשה להעלאת שכר</h3>
                <button 
                  onClick={() => setIsModalOpen(false)} 
                  className="text-gray-500 hover:text-gray-700 transition-colors p-2 hover:bg-gray-100 rounded-full"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
            </div>

            <div className="max-h-[calc(100vh-200px)] overflow-y-auto px-2">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">סניף</label>
                    <select
                      name="branchId"
                      value={formData.branchId}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right appearance-none"
                      style={{ backgroundPosition: "left 0.5rem center" }}
                    >
                      <option value="">בחר סניף</option>
                      {branches.map(branch => (
                        <option key={branch._id} value={branch._id}>
                          {branch.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">שם העובד</label>
                    <input
                      type="text"
                      name="employeeName"
                      value={formData.employeeName}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">מספר עובד</label>
                    <input
                      type="text"
                      name="employeeNumber"
                      value={formData.employeeNumber}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">תעודת זהות</label>
                    <input
                      type="text"
                      name="idNumber"
                      value={formData.idNumber}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">תפקיד</label>
                    <input
                      type="text"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">סוג שכר</label>
                    <select
                      name="salaryType"
                      value={formData.salaryType}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right appearance-none"
                      style={{ backgroundPosition: "left 0.5rem center" }}
                    >
                      <option value="hourly">שעתי</option>
                      <option value="monthly">חודשי</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">שכר נוכחי</label>
                    <input
                      type="number"
                      name="currentSalary"
                      value={formData.currentSalary}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">שכר מבוקש</label>
                    <input
                      type="number"
                      name="requestedSalary"
                      value={formData.requestedSalary}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-semibold text-gray-700 mb-2">ותק בחברה (בשנים)</label>
                    <input
                      type="number"
                      name="yearsInCompany"
                      value={formData.yearsInCompany}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">נימוק לבקשה</label>
                  <textarea
                    name="justification"
                    value={formData.justification}
                    onChange={handleChange}
                    required
                    rows="4"
                    className="block w-full rounded-lg border-0 bg-white/50 shadow-md focus:ring-2 focus:ring-blue-500 px-4 py-2.5 text-right"
                  ></textarea>
                </div>

                <div className="sticky bottom-0 bg-white pt-4 border-t border-gray-200 mt-6">
                  <div className="flex justify-end gap-4">
                    <button
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                      className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                    >
                      ביטול
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary shadow-lg transform hover:scale-105 transition-all duration-300"
                    >
                      שלח בקשה
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AreaManagerSalaryUpgrades;